import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import {useMediaQuery} from 'react-responsive';
import {TextField, Typography} from '@material-ui/core';
import AlertSending from '../../../components/AlertSeding';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {realFormat} from '../../../configuration/realFormat';

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AlertAnswerBox = ({
  isOpen,
  handleClose,
  title,
  description,
  onSubmit,
  onRefuse,
  isLoading,
  setLoading,
  setVlr,
  vlr,
  handleNotiStack,
  vlrMax,
  vlrRqst,
}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [desc, setDesc] = useState('');

  const close = useCallback(() => {
    handleClose();
  }, []);

  const onSave = useCallback(() => {
    if (vlr > vlrMax) {
      handleNotiStack(
        'O valor do reembolso precisa ser menor ou igual do que o valor do pedio',
        'error',
      );
    } else {
      onSubmit(desc);
    }
  }, [desc, vlr, vlrMax]);

  const onDecline = useCallback(() => {
    if (desc == '') {
      handleNotiStack('Descreva porquê o reembolso foi negado', 'error');
    } else {
      onRefuse(desc);
    }
  }, [desc]);

  function handlyvalue(value) {
    setVlr(value);
  }

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingLeft: 10,
            paddingRight: 10,
            maxWidth: 500,
            marginLeft: 40,
            marginRight: 40,
            marginTop: 10,
          }}
        >
          <Typography style={{textAlign: 'center'}} variant="h5">
            {title}
          </Typography>
          <Typography style={{textAlign: 'center', maxWidth: 300}} variant="h8">
            {description}
          </Typography>
          <Typography
            style={{textAlign: 'center', marginTop: 30, marginBottom: 0}}
            variant="h8"
          >
            Valor solicitado
          </Typography>
          <Typography
            style={{textAlign: 'center', marginTop: 0, fontWeight: 'bold'}}
            variant="h5"
          >
            {realFormat(vlrRqst)}
          </Typography>
          <Divider
            variant="middle"
            style={{background: '#06448E', width: '100%'}}
          />
          <Typography
            style={{textAlign: 'center', marginTop: 30, marginBottom: 0}}
            variant="h8"
          >
            Resposta:
          </Typography>
          <Typography
            style={{
              textAlign: 'center',
              marginTop: 0,
              marginBottom: 0,
              color: '#06448E',
            }}
            variant="h9"
          >
            Valor maximo do reembolso é {realFormat(vlrMax)}
          </Typography>
          <CurrencyTextField
            style={{marginRight: 10, width: '100%', marginTop: 20}}
            inputProps={{style: {fontSize: 14, padding: 15}}}
            InputLabelProps={{style: {fontSize: 14}}}
            label="Quanto você deseja reembolsar?"
            variant="standard"
            value={vlr}
            currencySymbol="R$"
            minimumValue="0"
            outputFormat="string"
            decimalCharacter=","
            digitGroupSeparator="."
            onChange={(event, value) => handlyvalue(value)}
          />
          <TextField
            multiline={true}
            size={'small'}
            style={{
              width: '100%',
              marginTop: 10,
              marginBottom: 30,
              textAlign: 'center',
            }}
            id="resposta"
            label="Descreva o motivo"
            inputProps={{style: {fontSize: 14}, maxLength: 1000}}
            InputLabelProps={{style: {fontSize: 14}}}
            defaultValue={desc}
            value={desc}
            onChange={event => {
              setDesc(event.target.value);
            }}
          />
        </div>

        <Divider variant="middle" />

        <DialogActions style={{position: 'relative', bottom: 0}}>
          {isSmall ? (
            <Button
              size={'small'}
              autoFocus
              onClick={() => onDecline()}
              color="secondary"
            >
              Recusar
            </Button>
          ) : (
            <Button autoFocus onClick={() => onDecline()} color="secondary">
              Recusar
            </Button>
          )}
          {isSmall ? (
            <Button
              size={'small'}
              autoFocus
              onClick={() => onSave()}
              color="primary"
            >
              Aprovar
            </Button>
          ) : (
            <Button autoFocus onClick={() => onSave()} color="primary">
              Aprovar
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <AlertSending bopen={isLoading} handleClose={() => setLoading(false)} />
    </div>
  );
};

AlertAnswerBox.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  onSubmit: PropTypes.func,
  onRefuse: PropTypes.func,
  selectedItem: PropTypes.object,
  isLoading: PropTypes.bool,
  setLoading: PropTypes.func,
  vlr: PropTypes.number,
  setVlr: PropTypes.func,
  handleNotiStack: PropTypes.func,
  vlrMax: PropTypes.number,
  vlrRqst: PropTypes.number,
};

AlertAnswerBox.defaultProps = {
  isOpen: false,
};

export default AlertAnswerBox;
