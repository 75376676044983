/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {useMediaQuery} from 'react-responsive';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import {format} from '../../configuration/format';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import {makeStyles} from '@material-ui/core/styles';
import DialogPd from './dialogPd';
import {realFormat} from '../../configuration/realFormat';
import CircularProgress from '@material-ui/core/CircularProgress';

const columns = [
  {id: 'pedido', label: 'Pedido', minWidth: 100},
  {id: 'recebido', label: 'Recebido', minWidth: 100},
  {id: 'rfnd', label: 'Reembolso', minWidth: 100},
  {
    id: 'taxa',
    label: 'Taxa',
    minWidth: 100,
  },
  {
    id: 'subt',
    label: 'Subtotal do pedido',
    minWidth: 100,
  },
  {
    id: 'taxaii',
    label: 'Taxa Irê it',
    minWidth: 100,
  },
  {
    id: 'txcr',
    label: 'Taxa de serviço Irê it',
    minWidth: 100,
  },
  {
    id: 'txfrm',
    label: 'Taxa forma de pagamento',
    minWidth: 100,
  },
  {
    id: 'frm',
    label: 'Forma de pagamento',
    minWidth: 150,
  },
  {
    id: 'frete',
    label: 'Entrega própria',
    minWidth: 100,
  },
  {
    id: 'freteii',
    label: 'Entrega Irê it',
    minWidth: 100,
  },
  {
    id: 'dsc',
    label: 'Desconto da loja',
    minWidth: 100,
  },
  {
    id: 'dscii',
    label: 'Desconto Irê it',
    minWidth: 100,
  },
  {
    id: 'seta',
    label: '',
    minWidth: 100,
  },
  {
    id: 'ptstore',
    label: 'Parte do estabelecimento',
    minWidth: 100,
  },
  {
    id: 'pti',
    label: 'Parte Irê it',
    minWidth: 100,
  },
  {
    id: 'pte',
    label: 'Parte entregador',
    minWidth: 100,
  },
];

function createData(
  pedido,
  recebido,
  rfnd,
  taxa,
  subt,
  taxaii,
  txcr,
  txfrm,
  frm,
  frete,
  freteii,
  dsc,
  dscii,
  seta,
  ptstore,
  pti,
  pte,
  _id,
  type,
) {
  return {
    pedido,
    recebido,
    rfnd,
    taxa,
    subt,
    taxaii,
    txcr,
    txfrm,
    frm,
    frete,
    freteii,
    dsc,
    dscii,
    seta,
    ptstore,
    pti,
    pte,
    _id,
    type,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    paddingBottom: 20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// eslint-disable-next-line react/prop-types
const DetailsFat = ({
  nome,
  bopen,
  handleClose,
  fat,
  rowSelected,
  handleNotiStack,
}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const [comment, setComment] = useState('');
  const [open, setOpen] = React.useState(false);
  const handleClose2 = () => {
    setValue(0);
    setOpen(false);
  };
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };

  function close() {
    setValue(0);
    setLoading(true);
    handleClose();
  }

  function onSave() {
    setTimeout(() => {
      handleClose();
    }, 100);
  }

  const handleClickOpen2 = () => {
    setOpen(true);
  };

  const [docId, setDocId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  function openDialogPd(docid) {
    setDocId(docid);
    setOpenDialog(true);
  }

  const [rows, setRows] = useState([]);

  useEffect(() => {
    let map = fat.itens.map((item, index) => {
      return createData(
        '#' + item.npd,
        realFormat(item.rcb),
        realFormat(item.rfnd),
        item.taxa * 100 + '%',
        realFormat(item.subt + item.rfnd),
        realFormat(item.subt * item.taxa),
        realFormat(item.txcr),
        realFormat(item.txfrm),
        item.frm,
        realFormat(item.frete),
        realFormat(item.freteii),
        realFormat(item.dsc),
        realFormat(item.dscii),
        '',
        realFormat(item.ptst),
        realFormat(item.pti + item.txcr),
        realFormat(item.pte),
        item._id,
        item.type,
      );
    });

    setRows(map);
  }, [rowSelected]);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  }, [isLoading]);

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 100,
              paddingBottom: 100,
              height: 500,
              width: 500,
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div>
            <div
              style={{
                backgroundColor: '#FFffff',
                width: isVerySmall ? 'auto' : 300,
                height: 'auto',
                paddingLeft: 10,
                paddingRight: 10,
              }}
            ></div>
            <div
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  color: '#595959',
                  marginLeft: 0,
                  marginRight: 10,
                  fontSize: 20,
                }}
              >
                {nome}:
              </p>
              <p>Periodo: </p>
              <p>{rowSelected.Período}</p>
              <div>
                {rowSelected.Status.status === 'pago' ? (
                  <div>
                    <div
                      style={{
                        backgroundColor: '#eeffee',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingTop: 5,
                        paddingBottom: 0,
                        borderRadius: 5,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p
                          style={{
                            fontSize: 14,
                            marginTop: 0,
                            marginRight: 3,
                            color: 'rgba(57, 180, 13, 1)',
                          }}
                        >
                          R$
                        </p>
                        <p
                          style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            marginTop: -1,
                            color: 'rgba(57, 180, 13, 1)',
                          }}
                        >
                          {format(rowSelected.Status.vlr)}
                        </p>
                      </div>
                      <p
                        style={{
                          marginTop: -20,
                          fontSize: 12,
                          color: 'rgba(57, 180, 13, 1)',
                        }}
                      >
                        Pago em {rowSelected.Status.data}
                      </p>
                    </div>
                  </div>
                ) : rowSelected.Status.status === 'agendado' ? (
                  <div
                    style={{
                      backgroundColor: '#f4f4f4',
                      alignItems: 'center',
                      paddingLeft: 10,
                      paddingTop: 5,
                      paddingBottom: 0,
                      borderRadius: 5,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          marginTop: 0,
                          marginRight: 3,
                        }}
                      >
                        R$
                      </p>
                      <p
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                          marginTop: -1,
                        }}
                      >
                        {format(rowSelected.Status.vlr)}
                      </p>
                    </div>
                    <p
                      style={{
                        marginTop: -20,
                        fontSize: 12,
                        color: '#999999',
                      }}
                    >
                      Repasse previsto até {rowSelected.Status.data}
                    </p>
                  </div>
                ) : rowSelected.Status.status === 'aberto' ? (
                  <div
                    style={{
                      backgroundColor: '#ffffff',
                      alignItems: 'center',
                      paddingLeft: 10,
                      paddingTop: 5,
                      paddingBottom: 0,
                      borderRadius: 5,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          marginTop: 0,
                          marginRight: 3,
                        }}
                      >
                        R$
                      </p>
                      <p
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                          marginTop: -1,
                        }}
                      >
                        {format(rowSelected.Status.vlr)}
                      </p>
                    </div>
                    <p
                      style={{
                        marginTop: -20,
                        fontSize: 12,
                        color: '#999999',
                      }}
                    >
                      Em aberto. Pagar até: {rowSelected.Status.data}
                    </p>
                  </div>
                ) : rowSelected.Status.status === 'atrasado' ? (
                  <div
                    style={{
                      backgroundColor: '#fff4f4',
                      alignItems: 'center',
                      paddingLeft: 10,
                      paddingTop: 5,
                      paddingBottom: 0,
                      borderRadius: 5,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          marginTop: 0,
                          marginRight: 3,
                          color: '#ff3333',
                        }}
                      >
                        R$
                      </p>
                      <p
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                          marginTop: -1,
                          color: '#ff3333',
                        }}
                      >
                        {format(rowSelected.Status.vlr)}
                      </p>
                    </div>
                    <p
                      style={{
                        marginTop: -20,
                        fontSize: 12,
                        color: '#ff9999',
                      }}
                    >
                      Em atraso
                    </p>
                  </div>
                ) : rowSelected.Status.status === 'aguardando' ? (
                  <div>
                    {rowSelected.Status.vlr < 0 ? (
                      <div
                        style={{
                          backgroundColor: '#ffffcc',
                          alignItems: 'center',
                          paddingLeft: 10,
                          paddingTop: 5,
                          paddingBottom: 0,
                          borderRadius: 5,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <p
                            style={{
                              fontSize: 14,
                              marginTop: 0,
                              marginRight: 3,
                              color: '#ff6600',
                            }}
                          >
                            R$
                          </p>
                          <p
                            style={{
                              fontSize: 20,
                              fontWeight: 'bold',
                              marginTop: -1,
                              color: '#ff6600',
                            }}
                          >
                            {format(rowSelected.Status.vlr)}
                          </p>
                        </div>
                        <p
                          style={{
                            marginTop: -20,
                            fontSize: 12,
                            color: '#ff6600',
                          }}
                        >
                          Saldo devedor
                        </p>
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundColor: '#e8ffff',
                          alignItems: 'center',
                          paddingLeft: 10,
                          paddingTop: 5,
                          paddingBottom: 0,
                          borderRadius: 5,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <p
                            style={{
                              fontSize: 14,
                              marginTop: 0,
                              marginRight: 3,
                              color: '#06448e',
                            }}
                          >
                            R$
                          </p>
                          <p
                            style={{
                              fontSize: 20,
                              fontWeight: 'bold',
                              marginTop: -1,
                              color: '#06448e',
                            }}
                          >
                            {format(rowSelected.Status.vlr)}
                          </p>
                        </div>
                        <p
                          style={{
                            marginTop: -20,
                            fontSize: 12,
                            color: '#06448e',
                          }}
                        >
                          Aguardando agendamento. Previsão: até{' '}
                          {rowSelected.Status.data}
                        </p>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
              <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map(column => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{minWidth: column.minWidth}}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map(row => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                              onClick={() => openDialogPd(row._id)}
                              style={{
                                backgroundColor:
                                  row.type === 'storecredito'
                                    ? '#bbffbb'
                                    : row.type === 'storedebito'
                                    ? '#ffffcc'
                                    : '#ffffff',
                              }}
                            >
                              {columns.map(column => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.id === 'seta' ? (
                                      <ArrowRightAltIcon />
                                    ) : column.format &&
                                      typeof value === 'number' ? (
                                      column.format(value)
                                    ) : (
                                      value
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  labelRowsPerPage={'Linhas por tabela'}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    marginBottom: 10,
                    marginLeft: 10,
                  }}
                >
                  <div
                    style={{display: 'flex', alignItems: 'center', gap: '5px'}}
                  >
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: '#bbffbb',
                        borderRadius: '3px',
                      }}
                    ></div>
                    <span>Cancelado com Créditos</span>
                  </div>
                  <div
                    style={{display: 'flex', alignItems: 'center', gap: '5px'}}
                  >
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: '#ffffcc',
                        borderRadius: '3px',
                      }}
                    ></div>
                    <span>Cancelados com Débitos</span>
                  </div>
                  <div
                    style={{display: 'flex', alignItems: 'center', gap: '5px'}}
                  >
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: '#ffffff',
                        borderRadius: '3px',
                        border: '1px solid #ccc',
                      }}
                    ></div>
                    <span>Finalizado</span>
                  </div>
                </div>
              </Paper>
            </div>
          </div>
        )}

        <DialogActions style={{position: 'relative', bottom: 0}}>
          {isVerySmall ? (
            <div>
              <Button size={'small'} onClick={() => close()}>
                Fechar
              </Button>
            </div>
          ) : (
            <div>
              <Button onClick={() => close()}>Fechar</Button>
            </div>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={() => close()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Excluir item?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja deletar o item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Não
          </Button>
          <Button onClick={handleClose2} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <DialogPd
        docId={docId}
        bopen={openDialog}
        handleNotiStack={handleNotiStack}
        handleClose={() => setOpenDialog(false)}
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
    </div>
  );
};

export default DetailsFat;
