/* eslint-disable react/prop-types */
import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import LoopIcon from '@material-ui/icons/Loop';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

const statusMessages = {
  0: {
    text: 'Procurando entregador...',
    text2: '',
    color: '#E8FFFF',
    icon: <LoopIcon style={{color: '#06448e'}} />,
  },
  1: {
    text: 'O entregador está a caminho.',
    text2: '',
    color: '#E8FFFF',
    icon: <RadioButtonCheckedIcon style={{color: '#06448e'}} />,
  },
  2: {
    text: 'O entregador chegou ao estabelecimento.',
    text2: '',
    color: '#E8FFFF',
    icon: <WarningIcon style={{color: '#ff6600'}} />,
  },
  3: {
    text: 'O entregador está levando o pedido.',
    text2: 'O entregador será notificado e retornará com o pedido',
    color: '#E8FFFF',
    icon: <RadioButtonCheckedIcon style={{color: '#06448e'}} />,
  },
  4: {
    text: 'O entregador está voltando.',
    text2: 'O entregador será notificado e retornará com o pedido',
    color: '#E8FFFF',
    icon: <WarningIcon style={{color: '#ff6600'}} />,
  },
  5: {
    text: 'Entrega finalizada.',
    text2: '',
    color: '#E8FFFF',
    icon: <LoopIcon style={{color: '#06448e'}} />,
  },
  6: {
    text: 'Entrega cancelada.',
    text2: '',
    color: '#E8FFFF',
    icon: <WarningIcon style={{color: '#ff6600'}} />,
  },
  7: {
    text: 'Aguardando cliente.',
    text2: 'O entregador será notificado e retornará com o pedido',
    color: '#E8FFFF',
    icon: <RadioButtonCheckedIcon style={{color: '#06448e'}} />,
  },
};

const OrderCancellationInfo = ({DATA}) => {
  return (
    <div
      style={{
        padding: 16,
        borderRadius: 8,
        backgroundColor: '#ffffff',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
        maxWidth: 400,
      }}
    >
      <Chip
        icon={statusMessages[DATA.esttii]?.icon}
        label={statusMessages[DATA.esttii]?.text || 'Status desconhecido'}
        style={{
          backgroundColor: statusMessages[DATA.esttii]?.color,
          color: '#3D3D3D',
          fontSize: 14,
          fontWeight: 'normal',
          marginBottom: 8,
          padding: 5,
        }}
      />

      {DATA.esttii === 2 ||
      DATA.esttii === 3 ||
      DATA.esttii === 4 ||
      DATA.esttii === 7 ? (
        <p
          style={{
            fontSize: 14,
            color: '#ff6600',
            fontWeight: 'bold',
            marginTop: 5,
          }}
        >
          Taxa de cancelamento aplicada
        </p>
      ) : null}

      {DATA.esttii === 2 ? (
        <p style={{fontSize: 14, color: '#3D3D3D'}}>
          Caso cancele, será cobrada uma taxa de deslocamento do entregador,
          pois ele já se deslocou até o estabelecimento. Evite confirmar pedidos
          que você não pode entregar para não impactar futuras entregas.
        </p>
      ) : DATA.esttii === 3 || DATA.esttii === 4 || DATA.esttii === 7 ? (
        <p style={{fontSize: 14, color: '#3D3D3D'}}>
          Caso cancele, será cobrada a taxa completa de entrega, pois o
          entregador já realizou grande parte do percurso. Evite confirmações
          indevidas para manter sua reputação na plataforma.
        </p>
      ) : (
        <p style={{fontSize: 14, color: '#3D3D3D'}}>
          Caso cancele agora, o valor não será cobrado. No entanto,
          cancelamentos frequentes podem prejudicar sua loja na plataforma.
        </p>
      )}

      <p
        style={{
          fontSize: 14,
          color: '#000000',
          fontWeight: 'bold',
          marginTop: 10,
        }}
      >
        {DATA.esttii === 2
          ? 'A taxa de deslocamento será aplicada na fatura da loja.'
          : DATA.esttii === 3 || DATA.esttii === 4 || DATA.esttii === 7
          ? 'A taxa completa de entrega será aplicada na fatura da loja.'
          : 'Nenhuma taxa será aplicada neste momento.'}
      </p>

      {DATA.esttii === 3 || DATA.esttii === 4 || DATA.esttii === 7 ? (
        <Tooltip
          title={statusMessages[DATA.esttii]?.text2 || 'Status desconhecido'}
          arrow
        >
          <Chip
            icon={statusMessages[DATA.esttii]?.icon}
            label={statusMessages[DATA.esttii]?.text2 || 'Status desconhecido'}
            style={{
              width: '100%',
              backgroundColor: statusMessages[DATA.esttii]?.color,
              color: '#3D3D3D',
              fontSize: 12,
              fontWeight: 'normal',
              marginBottom: 8,
              padding: 5,
              whiteSpace: 'normal', // Permite quebrar linhas
              wordWrap: 'break-word', // Garante que palavras grandes não sejam cortadas
              textAlign: 'left', // Ajusta a posição do texto
            }}
          />
        </Tooltip>
      ) : null}
    </div>
  );
};

export default OrderCancellationInfo;
