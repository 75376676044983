/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {
  useLayoutEffect,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import {useMediaQuery} from 'react-responsive';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {FaBars} from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Box, Button, Divider} from '@material-ui/core';
import {dateformatinvert} from '../../configuration/dateformatinvert';
import {format} from '../../configuration/format';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';

import AssignmentIcon from '@material-ui/icons/Assignment';
import UpdateIcon from '@material-ui/icons/Update';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';

import {useSelector} from 'react-redux';
import {useReactToPrint} from 'react-to-print';
import ComponentToPrint from './printer';

import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import AlertSending from '../../components/AlertSeding';
import AlertRequest from '../../components/AlertRequest';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import LoopIcon from '@material-ui/icons/Loop';
import AddIcon from '@material-ui/icons/Add';
import WarningIcon from '@material-ui/icons/Warning';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {realFormat} from '../../configuration/realFormat';
import {secinmillis} from '../../configuration/secinmillis';
import {timeinmillis} from '../../configuration/timeinmillis';
import {getAuth} from 'firebase/auth';
import Tooltip from '@material-ui/core/Tooltip';
import AlertAnswerBox from './AlertAnswerBox';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DialogTitle = withStyles(styles)(props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// eslint-disable-next-line react/prop-types
const Itens = ({key, nome, cqtd}) => {
  return (
    <div
      key={key}
      style={{
        marginLeft: 10,
        marginRight: 10,
        paddingTop: 0,
        paddingBottom: 3,
      }}
    >
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          marginTop: 0,
        }}
      >
        <div>
          <p
            style={{
              backgroundColor: '#E9E9E9',
              marginRight: 5,
              borderRadius: 40,
              paddingBottom: 3,
              paddingTop: 3,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 14,
              color: '#999999',
            }}
          >
            {cqtd}
          </p>
        </div>
        <div>
          <p style={{color: '#999999', fontSize: 14, marginRight: 25}}>
            {nome}
          </p>
        </div>
      </div>
    </div>
  );
};

const ListItens = ({listitem}) => {
  let currentlist = listitem.itens;
  let listitens = currentlist.map(item2 => {
    let newlist = item2.data.map(item3 => {
      if (item3.qtd >= 1) {
        return {nome: item3.nome, qtd: item3.qtd};
      }
    });

    let list = newlist.filter(function (element) {
      return element !== undefined;
    });
    return list;
  });

  const views = [];

  for (let i = 0; i < listitens.length; i++) {
    for (let j = 0; j < listitens[i].length; j++) {
      views.push(
        <Itens
          key={i + j}
          nome={listitens[i][j].nome}
          cqtd={listitens[i][j].qtd}
        />,
      );
    }
  }
  return views;
};

const GreenCheckbox = withStyles({
  root: {
    color: '#C4C4C4',
    '&$checked': {
      color: '#06448E',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const Refounds = ({
  handleToggleSidebar,
  handleNotiStack,
  handleBackDrop,
  pedidos,
  setPedidos,
  sendMessage,
  updatePedidos,
}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const estado = useSelector(state => state.setstatescreen.estado);
  const [isLoading, setLoading] = useState(true);
  const [changeData, setChangeData] = useState(false);
  const [isLoadingAnwser, setLoadingAnwser] = useState(false);
  const [currentItem, setCurrentItem] = useState('');
  const classes = useStyles();
  const [bopen, setbopen] = useState(false);
  const [bopenentrega, setbopenentrega] = useState(false);
  const [openarquivaritem, setopenarquivaitem] = useState(false);
  const [opencancelitem, setopencancelitem] = useState(false);
  const [openarquivarall, setopenarquivarall] = useState(false);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [np1, setNp1] = useState('');
  const [np2, setNp2] = useState('');
  const [np3, setNp3] = useState('');
  const [cp1, setCp1] = useState('');
  const [cp2, setCp2] = useState('');
  const [cp3, setCp3] = useState('');
  const [isp2, setp2] = useState(false);
  const [isp3, setp3] = useState(false);
  const [isEntBack, setEntBack] = useState(false);
  const [isReadyEnt, setReadyEnt] = useState(false);
  const [vlrent, setVlrent] = useState(0.0);
  const [bvlrent, setBvlrent] = useState(false);
  const [loadvlr, setLoadvlr] = useState(false);
  const [entnotfound, setEntnotfound] = useState(false);

  const [PricesII, setPricesII] = useState([]);
  const [PricesIIId, setPricesIIId] = useState('');
  const [distance, setDistance] = useState(0.0);
  const [duration, setDuration] = useState(0.0);
  const [urlRoute, setUrlRoute] = useState('');
  const [freteII, setFreteII] = useState(0.0);
  const [openDialogOc, setOpenDialogOc] = useState(false);

  const currentTimeSaoPaulo = new Date().toLocaleString('en-US', {
    timeZone: 'America/Sao_Paulo',
  });
  const currentTime = new Date(currentTimeSaoPaulo); // Converte para objeto Date
  const futureTime = new Date(currentTime.getTime() + 24 * 60 * 60 * 1000);

  const clat = useSelector(
    state => state.setrestaurante.location.coordinates[1],
  );
  const clng = useSelector(
    state => state.setrestaurante.location.coordinates[0],
  );

  const [value, setValue] = useState();
  function handlyvalue(value) {
    setValue(value);
  }

  const estabelecimento = useSelector(state => state.setrestaurante.nome);
  const idstore = useSelector(state => state.setrestaurante.idstore);
  const iitx = useSelector(state => state.setrestaurante.iitx);
  const iietx = useSelector(state => state.setrestaurante.iietx);
  const [DATA, setDATA] = useState({itens: []});
  const [EDATA, setEDATA] = useState({itens: [], _id: ''});
  const [p1, setP1] = useState();
  const [p2, setP2] = useState();
  const [p3, setP3] = useState();
  const [frmEnt, setFrmEnt] = useState('');
  const [troco, setTroco] = useState(0.0);
  const [notaEnt, setNotaEnt] = useState({nota: 5});
  const [entregapedidos, setentregapedidos] = useState([]);

  const [reason, setReason] = useState('');
  const [vlr, setVlr] = useState('');
  const [stt, setStt] = useState(false);

  const handleChange = event => {
    setP1(event.target.value);
    setNp1(event.target.value.nome);
    setCp1(event.target.value.ctt);
    setEDATA(event.target.value);
  };

  const handleChange2 = event => {
    setP2(event.target.value);
    setNp2(event.target.value.nome);
    setCp2(event.target.value.ctt);
  };

  const handleChange3 = event => {
    setP3(event.target.value);
    setNp3(event.target.value.nome);
    setCp3(event.target.value.ctt);
  };

  function handleEntBack() {
    setEntBack(!isEntBack);
  }

  const [efrms, setEfrms] = useState([
    {
      title: 'Dinheiro',
    },
    {
      title: 'Maquininha',
    },
  ]);

  const [entreganotas, setEntreganotas] = useState([
    {
      nota: 5,
    },
    {
      nota: 4,
    },
    {
      nota: 3,
    },
    {
      nota: 2,
    },
    {
      nota: 1,
    },
  ]);

  useEffect(() => {
    setNotaEnt(entreganotas[0]);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  useEffect(() => {
    updatePedidos();
  }, [estado]);

  const [isBefore24h, setIsBefore24h] = useState(false);
  useEffect(() => {
    const updateCheck = () => {
      const currentTimeSaoPaulo = new Date().toLocaleString('en-US', {
        timeZone: 'America/Sao_Paulo',
      });

      const currentTime = new Date(currentTimeSaoPaulo);
      const hracptDate = new Date(DATA.hracpt);
      const hracptPlus24h = new Date(
        hracptDate.getTime() + 24 * 60 * 60 * 1000,
      );

      setIsBefore24h(currentTime < hracptPlus24h);
    };

    updateCheck();

    const interval = setInterval(updateCheck, 60000);

    return () => clearInterval(interval);
  }, [DATA]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const Lista = () => {
    let list = [];
    if (DATA.itens === undefined || DATA.itens.length === 0) {
      list = [];
    } else {
      list = DATA.itens.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              borderTopWidth: 1,
              borderTopColor: '#d9d9d9',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginTop: 0,
                width: '100%',
              }}
            >
              {item.bpeso ? (
                <div>
                  {item.cqtd * item.intpeso * 1000 >= 1000 ? (
                    <p
                      style={{
                        backgroundColor: '#d9d9d9',
                        marginRight: 5,
                        borderRadius: 40,
                        paddingBottom: 3,
                        paddingTop: 3,
                        paddingLeft: 5,
                        paddingRight: 5,
                        fontSize: 14,
                      }}
                    >
                      {(item.cqtd * item.intpeso * 1000) / 1000} kg
                    </p>
                  ) : (
                    <p
                      style={{
                        backgroundColor: '#d9d9d9',
                        marginRight: 5,
                        borderRadius: 40,
                        paddingBottom: 3,
                        paddingTop: 3,
                        paddingLeft: 5,
                        paddingRight: 5,
                        fontSize: 14,
                      }}
                    >
                      {item.cqtd * item.intpeso * 1000} g
                    </p>
                  )}
                </div>
              ) : (
                <div>
                  <p
                    style={{
                      backgroundColor: '#d9d9d9',
                      marginRight: 5,
                      borderRadius: 40,
                      paddingBottom: 3,
                      paddingTop: 3,
                      paddingLeft: 5,
                      paddingRight: 5,
                      fontSize: 14,
                    }}
                  >
                    {item.cqtd}
                  </p>
                </div>
              )}
              <div style={{flex: 0.75}}>
                <p style={{color: '#000000', fontSize: 14, marginRight: 25}}>
                  {item.nome}
                </p>
                {item.itens.length === 0 ? null : (
                  <div style={{marginTop: 10}}>
                    <ListItens key={index} listitem={item} />
                  </div>
                )}
                {item.obs === '' ? null : (
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <p style={{color: '#999999', marginRight: 10}}>
                      Obs: {item.obs}
                    </p>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flex: 0.25,
                }}
              >
                <p style={{color: '#000000', fontSize: 14}}>
                  R$ {format(item.cqtd * item.vlr)}
                </p>
              </div>
            </div>
          </div>
        );
      });
    }

    return list;
  };

  const ListaPedidos = () => {
    let listrequest = pedidos.map((item, index) => {
      if (item.hrrfnd === 0) {
        return {...item};
      }
    });

    let requestItens = listrequest.filter(function (element) {
      return element !== undefined;
    });

    let listfinalizado = pedidos.map((item, index) => {
      if (item.hrrfnd > 0) {
        return item;
      }
    });

    let finalizadoItens = listfinalizado.filter(function (element) {
      return element !== undefined;
    });

    return (
      <div style={{marginTop: 0}}>
        <div>
          <div
            style={{
              backgroundColor: '#c9c9c9',
              height: 40,
              alignItems: 'center',
              justifyContent: 'flex-start',
              display: 'flex',
              paddingRight: 5,
              paddingLeft: 5,
            }}
          >
            <AssignmentIcon style={{marginLeft: 0, marginRight: 5}} />
            <p>Pendente</p>
          </div>
          <div style={{paddingLeft: 5, paddingRight: 5}}>
            <ListStatus list={requestItens} />
          </div>
        </div>
        <div>
          <div
            style={{
              backgroundColor: '#c9c9c9',
              height: 40,
              alignItems: 'center',
              justifyContent: 'space-between',
              display: 'flex',
              paddingRight: 0,
              paddingLeft: 5,
            }}
          >
            <div style={{display: 'flex', alignItems: 'center'}}>
              <CheckCircleIcon style={{marginLeft: 0, marginRight: 5}} />
              <p>Finalizados</p>
            </div>
            <div>
              <Button
                color={'primary'}
                size={'small'}
                onClick={() => setopenarquivarall(true)}
              >
                Arquivar
              </Button>
            </div>
          </div>
          <div style={{paddingLeft: 5, paddingRight: 5}}>
            <ListFinalizado list={finalizadoItens} />
          </div>
        </div>
      </div>
    );
  };

  const ListStatus = ({list}) => {
    let liststatus = list.map((item, index) => {
      return (
        <Button
          onClick={() => clickItem(item)}
          style={{width: '100%', padding: 0, marginTop: 5, marginBottom: 5}}
          key={index}
        >
          {currentItem === item._id ? (
            <Box
              boxShadow={3}
              style={{
                marginTop: 0,
                width: '100%',
                paddingBottom: 5,
                borderRadius: 3,
                backgroundColor: '#ff6600',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  paddingLeft: 10,
                  paddingRight: 10,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{display: 'flex', width: '50%'}}>
                  <p
                    style={{
                      marginRight: 4,
                      color: '#ffffff',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item.nome}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 30,
                    borderWidth: 1.5,
                    borderColor: '#ffffff',
                    borderStyle: 'solid',
                    paddingLeft: 5,
                    paddingRight: 5,
                    height: 15,
                  }}
                >
                  <RadioButtonCheckedIcon
                    style={{fontSize: 10, color: '#ffffff'}}
                    className={classes.checkIcon}
                  />
                  <p
                    style={{
                      fontSize: 10,
                      marginLeft: 3,
                      color: '#ffffff',
                    }}
                  >
                    Pendente
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  paddingLeft: 10,
                  paddingRight: 10,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{display: 'flex'}}>
                  <p
                    style={{
                      marginRight: 4,
                      color: '#ffffff',
                      fontSize: 12,
                      marginTop: 0,
                    }}
                  >
                    Pedido:
                  </p>
                </div>
                <div>
                  <p
                    style={{color: '#f1f1f1', fontWeight: 'bold', marginTop: 0}}
                  >
                    #{item.npd}
                  </p>
                </div>
              </div>
            </Box>
          ) : (
            <Box
              boxShadow={3}
              style={{
                marginTop: 0,
                width: '100%',
                paddingBottom: 5,
                borderRadius: 3,
                backgroundColor: '#e8ffff',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  paddingLeft: 10,
                  paddingRight: 10,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{display: 'flex', width: '50%'}}>
                  <p
                    style={{
                      marginRight: 4,
                      color: '#393939',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item.nome}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 30,
                    borderWidth: 1.5,
                    borderColor: '#06448e',
                    borderStyle: 'solid',
                    paddingLeft: 5,
                    paddingRight: 5,
                    height: 15,
                  }}
                >
                  <RadioButtonCheckedIcon
                    style={{fontSize: 10}}
                    color="primary"
                    className={classes.checkIcon}
                  />
                  <p
                    style={{
                      fontSize: 10,
                      marginLeft: 3,
                      color: '06448e',
                    }}
                  >
                    Pendente
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  paddingLeft: 10,
                  paddingRight: 10,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{display: 'flex'}}>
                  <p
                    style={{
                      marginRight: 4,
                      color: '#393939',
                      fontSize: 12,
                      marginTop: 0,
                    }}
                  >
                    Pedido:
                  </p>
                </div>
                <div>
                  <p
                    style={{color: '#999999', marginTop: 0, fontWeight: 'bold'}}
                  >
                    #{item.npd}
                  </p>
                </div>
              </div>
            </Box>
          )}
        </Button>
      );
    });

    return liststatus;
  };

  function open2pedido() {
    setp2(true);
  }

  function deletep2() {
    setCp2('');
    setNp2('');
    setp2();
    setp2(false);
  }

  function closeEntrega() {
    setP1();
    setNp1('');
    setCp1('');
    setFrmEnt('');
    setEntBack(false);
    setReadyEnt(false);
    setVlrent(0.0);
    setBvlrent(false);
    setbopenentrega(false);
  }

  function clearItens() {
    setIsSending(true);
    setopenarquivarall(false);
    auth.currentUser.getIdToken().then(token => {
      const clearrequest = new Request(
        'https://kops.apiireit.com/gateway/pedidos/clear/refound?estid=' +
          idstore +
          '&finish=true',
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        },
      );

      fetch(clearrequest)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                updatePedidos();
                setTimeout(() => {
                  setbopen(false);
                  handleNotiStack('Pedidos arquivados', 'success');
                  setIsSending(false);
                  scrollToTop();
                }, 1000);
              })
              .catch(error => {
                console.error(error);
              });
          } else if (response.status === 503) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setIsSending(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  function arquivarItem() {
    setIsSending(true);
    setopenarquivaitem(false);
    auth.currentUser.getIdToken().then(token => {
      const clearrequest = new Request(
        'https://kops.apiireit.com/gateway/pedidos/clearitem/refound/' +
          DATA._id +
          '?estid=' +
          idstore +
          '&finish=true',
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        },
      );

      fetch(clearrequest)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                updatePedidos();
                setTimeout(() => {
                  setbopen(false);
                  handleNotiStack('Pedido arquivado', 'success');
                  setIsSending(false);
                  scrollToTop();
                }, 1000);
              })
              .catch(error => {
                console.error(error);
              });
          } else if (response.status === 503) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setIsSending(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  function opencancelitensalert() {
    setopencancelitem(true);
  }

  const ListFinalizado = ({list}) => {
    let liststatus = list.map((item, index) => {
      return (
        <Button
          onClick={() => clickItem(item)}
          style={{width: '100%', padding: 0, marginTop: 5, marginBottom: 5}}
          key={index}
        >
          {currentItem === item._id ? (
            <Box
              boxShadow={3}
              style={{
                marginTop: 0,
                width: '100%',
                paddingBottom: 5,
                borderRadius: 3,
                backgroundColor: '#ff6600',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  paddingLeft: 10,
                  paddingRight: 10,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{display: 'flex', width: '50%'}}>
                  <p
                    style={{
                      marginRight: 4,
                      color: '#ffffff',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item.nome}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 30,
                    borderWidth: 1.5,
                    borderColor: '#ffffff',
                    borderStyle: 'solid',
                    paddingLeft: 1,
                    paddingRight: 1,
                    height: 15,
                  }}
                >
                  <RadioButtonCheckedIcon
                    style={{fontSize: 10, color: '#ffffff'}}
                    className={classes.checkIcon}
                  />
                  <p
                    style={{
                      fontSize: 10,
                      marginLeft: 0,
                      color: '#ffffff',
                    }}
                  >
                    Finalizado
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  paddingLeft: 10,
                  paddingRight: 10,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{display: 'flex'}}>
                  <p
                    style={{
                      marginRight: 4,
                      color: '#ffffff',
                      fontSize: 12,
                      marginTop: 0,
                    }}
                  >
                    Pedido:
                  </p>
                </div>
                <p style={{color: '#ffffff', marginTop: 0, fontWeight: 'bold'}}>
                  #{item.npd}
                </p>
              </div>
            </Box>
          ) : (
            <Box
              boxShadow={3}
              style={{
                marginTop: 0,
                width: '100%',
                paddingBottom: 5,
                borderRadius: 3,
                backgroundColor: '#f4f4f4',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  paddingLeft: 10,
                  paddingRight: 10,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{display: 'flex', width: '50%'}}>
                  <p
                    style={{
                      marginRight: 4,
                      color: '#393939',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item.nome}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 30,
                    borderWidth: 1.5,
                    borderColor: '#06448e',
                    borderStyle: 'solid',
                    paddingLeft: 1,
                    paddingRight: 1,
                    height: 15,
                  }}
                >
                  <RadioButtonCheckedIcon
                    style={{fontSize: 8}}
                    color="primary"
                    className={classes.checkIcon}
                  />
                  <p
                    style={{
                      fontSize: 10,
                      marginLeft: 0,
                      color: '06448e',
                    }}
                  >
                    Finalizado
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  paddingLeft: 10,
                  paddingRight: 10,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{display: 'flex'}}>
                  <p
                    style={{
                      marginRight: 4,
                      color: '#393939',
                      fontSize: 12,
                      marginTop: 0,
                    }}
                  >
                    Pedido:
                  </p>
                </div>
                <div>
                  <p
                    style={{color: '#999999', marginTop: 0, fontWeight: 'bold'}}
                  >
                    #{item.npd}
                  </p>
                </div>
              </div>
            </Box>
          )}
        </Button>
      );
    });

    return liststatus;
  };

  function clickItem(item) {
    setChangeData(true);
    setDATA(item);
    setCurrentItem(item._id);
    setEDATA(item);
    setbopen(true);
    setTimeout(() => {
      setChangeData(false);
    }, 1000);
    setTimeout(() => {
      if (!isVerySmall) {
        scrollToTop();
      }
    }, 1500);
  }

  function close() {
    setbopen(false);
  }

  const [isSending, setIsSending] = useState(false);
  const [isRequest, setIsRequest] = useState(false);

  const onSubmit = useCallback(
    desc => {
      close();
      let updatedItem = {...DATA};
      moveItem(updatedItem._id, true, desc, vlr);
    },
    [DATA, stt, reason, vlr],
  );

  const onRefuse = useCallback(
    desc => {
      close();
      let updatedItem = {...DATA};
      moveItem(updatedItem._id, false, desc, 0.0);
    },
    [DATA, stt, reason, vlr],
  );

  function moveItem(id, stt, reason, vlr) {
    setIsSending(true);
    sendMessage('Atualização do pedido', 'update_pedido', {docid: id}, [
      DATA.userid,
    ]);
    let newpedidos = pedidos.map((item, index) => {
      if (item._id === id) {
        setDATA({...item, hrrfnd: 1});
        return {...item, hrrnd: 1};
      } else {
        return item;
      }
    });
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/pedidos/awnser/refound?pdid=' +
          id +
          '&vlr=' +
          vlr +
          '&status=' +
          stt +
          '&rsn=' +
          reason,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                updatePedidos();
                setTimeout(() => {
                  setbopen(false);
                  handleNotiStack('Pedido atualizado', 'success');
                  setOpenDialogBox(false);
                  setIsSending(false);
                  scrollToTop();
                }, 1000);
              })
              .catch(error => {
                setOpenDialogBox(false);
                console.error(error);
              });
          } else if (response.status === 503) {
            setOpenDialogBox(false);
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setOpenDialogBox(false);
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setOpenDialogBox(false);
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setOpenDialogBox(false);
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setOpenDialogBox(false);
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setOpenDialogBox(false);
          setIsSending(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [width, height] = useWindowSize();
  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Dialog
            onClose={() => setopenarquivaitem(false)}
            style={{
              marginLeft: isVerySmall ? -20 : 0,
              marginRight: isVerySmall ? -20 : 0,
              paddingLeft: 0,
              minWidth: isVerySmall ? '80%' : 0,
            }}
            aria-labelledby="customized-dialog-title"
            open={openarquivaritem}
          >
            <div
              style={{
                backgroundColor: '#ffffff',
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: 18,
                }}
              >
                Deseja arquivar o item?
              </p>
              <p style={{fontSize: 14, width: 200}}>
                Ao arquivar o item, ele ficará disponível apenas no histórico. E
                irá sair da tela de pedidos
              </p>
            </div>

            <DialogActions style={{position: 'relative', bottom: 0}}>
              <Button
                color={'secondary'}
                size={'small'}
                onClick={() => setopenarquivaitem(false)}
              >
                Cancelar
              </Button>
              <Button
                color={'primary'}
                size={'small'}
                onClick={() => arquivarItem()}
              >
                Arquivar
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            onClose={() => setopenarquivarall(false)}
            style={{
              marginLeft: isVerySmall ? -20 : 0,
              marginRight: isVerySmall ? -20 : 0,
              paddingLeft: 0,
              minWidth: isVerySmall ? '80%' : 0,
            }}
            aria-labelledby="customized-dialog-title"
            open={openarquivarall}
          >
            <div
              style={{
                backgroundColor: '#ffffff',
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: 18,
                }}
              >
                Deseja arquivar todos os itens?
              </p>
              <p style={{fontSize: 14, width: 200}}>
                Ao arquivar os itens, eles ficaram disponível apenas no
                histórico.
              </p>
            </div>

            <DialogActions style={{position: 'relative', bottom: 0}}>
              <Button
                color={'secondary'}
                size={'small'}
                onClick={() => setopenarquivarall(false)}
              >
                Cancelar
              </Button>
              <Button
                color={'primary'}
                size={'small'}
                onClick={() => clearItens()}
              >
                Arquivar
              </Button>
            </DialogActions>
          </Dialog>
          {isVisible ? (
            <Fab
              onClick={() => scrollToTop()}
              style={{position: 'fixed', bottom: 20, right: 20, zIndex: 2}}
              size={'small'}
              color="primary"
              aria-label="add"
            >
              <ArrowUpwardIcon />
            </Fab>
          ) : null}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginLeft: 10,
              marginTop: 10,
              marginRight: 10,
            }}
          >
            <div
              className="btn-toggle"
              onClick={() => handleToggleSidebar(true)}
            >
              <FaBars />
            </div>
          </div>

          <div style={{width: '100%'}}>
            {isVerySmall ? (
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#E9E9E9',
                  marginTop: 10,
                  marginBottom: 80,
                }}
              >
                <ListaPedidos />
                <Dialog
                  onClose={() => setbopen(false)}
                  style={{
                    marginLeft: isVerySmall ? -20 : 0,
                    marginRight: isVerySmall ? -20 : 0,
                    paddingLeft: 0,
                    minWidth: isVerySmall ? '95%' : 0,
                  }}
                  aria-labelledby="customized-dialog-title"
                  open={bopen}
                >
                  <div
                    style={{
                      width: '100%',
                      backgroundColor: '#ffffff',
                    }}
                  >
                    {changeData ? (
                      <div
                        style={{
                          display: 'flex',
                          flex: 1,
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: height,
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : DATA === undefined || DATA === null ? (
                      <div
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: height,
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        <InfoIcon style={{color: '#999999', fontSize: 25}} />
                        <p
                          style={{
                            fontSize: 25,
                            color: '#999999',
                            textAlign: 'center',
                          }}
                        >
                          Selecione um item
                        </p>
                      </div>
                    ) : (
                      <div>
                        <Box
                          boxShadow={3}
                          style={{
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                            borderRadius: 10,
                          }}
                        >
                          <p
                            style={{
                              color: '#000000',
                              fontWeight: 'bold',
                              fontSize: 20,
                              marginBottom: 0,
                            }}
                          >
                            Detalhes do reembolso
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: 0,
                              marginBottom: 0,
                            }}
                          >
                            <p
                              style={{
                                color: '#999999',
                                marginRight: 10,
                                marginBottom: 0,
                              }}
                            >
                              Motivo:
                            </p>
                            <p
                              style={{
                                color: '#000000',
                                fontWeight: 'bold',
                                marginBottom: 0,
                              }}
                            >
                              {DATA.rqstrfnd}
                            </p>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: 0,
                              marginBottom: 0,
                            }}
                          >
                            <p
                              style={{
                                color: '#999999',
                                marginRight: 10,
                                marginBottom: 0,
                              }}
                            >
                              Valor solicitado:
                            </p>
                            <p
                              style={{
                                color: '#000000',
                                fontWeight: 'bold',
                                marginBottom: 0,
                              }}
                            >
                              {realFormat(DATA.rqstvlrrfnd)}
                            </p>
                          </div>
                          {DATA.hrrfnd > 0 ? (
                            <div
                              style={{
                                display: 'flex',
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              <p
                                style={{
                                  color: '#999999',
                                  marginRight: 10,
                                  marginBottom: 0,
                                }}
                              >
                                Valor do reembolso:
                              </p>
                              <p
                                style={{
                                  color: '#000000',
                                  fontWeight: 'bold',
                                  marginBottom: 0,
                                }}
                              >
                                {realFormat(DATA.refound)}
                              </p>
                            </div>
                          ) : null}
                          {DATA.hrrfnd > 0 ? (
                            <div
                              style={{
                                display: 'flex',
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              <p
                                style={{
                                  color: '#999999',
                                  marginRight: 10,
                                  marginBottom: 0,
                                }}
                              >
                                Motivo do reembolso:
                              </p>
                              <p
                                style={{
                                  color: '#000000',
                                  fontWeight: 'bold',
                                  marginBottom: 0,
                                }}
                              >
                                {DATA.rsnrfnd}
                              </p>
                            </div>
                          ) : null}
                          <Divider variant="middle" style={{marginTop: 20}} />
                          <p
                            style={{
                              color: '#06448e',
                              fontWeight: 'bold',
                              fontSize: 16,
                              marginBottom: 0,
                            }}
                          >
                            Detalhes do cliente
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: 0,
                              marginBottom: 0,
                              paddingTop: 0,
                            }}
                          >
                            <p
                              style={{
                                color: '#999999',
                                marginRight: 10,
                                padding: 0,
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              Nome:
                            </p>
                            <p
                              style={{
                                color: '#393939',
                                fontWeight: 'bold',
                                padding: 0,
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              {DATA.nome}
                            </p>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: 0,
                              marginBottom: 0,
                            }}
                          >
                            <p
                              style={{
                                color: '#999999',
                                marginRight: 10,
                                padding: 0,
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              Contato:
                            </p>
                            <p
                              style={{
                                color: '#393939',
                                fontWeight: 'bold',
                                padding: 0,
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              {DATA.ctt}
                            </p>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: 0,
                              marginBottom: 0,
                            }}
                          >
                            <p
                              style={{
                                color: '#999999',
                                marginRight: 10,
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              Número de reembolsos:
                            </p>
                            <p
                              style={{
                                color: '#393939',
                                fontWeight: 'bold',
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              {DATA.nrfd}
                            </p>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: 0,
                              marginBottom: 0,
                            }}
                          >
                            <p
                              style={{
                                color: '#999999',
                                marginRight: 10,
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              Valor total de reembolso:
                            </p>
                            <p
                              style={{
                                color: '#393939',
                                fontWeight: 'bold',
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              {realFormat(DATA.vlrrfd)}
                            </p>
                          </div>
                        </Box>

                        {DATA.etrii ? (
                          <Box
                            style={{
                              paddingTop: 1,
                              paddingBottom: 10,
                              marginTop: 10,
                              borderRadius: 10,
                            }}
                            boxShadow={3}
                          >
                            <div
                              style={{
                                paddingLeft: 0,
                                paddingRight: 0,
                                marginLeft: 10,
                                marginRight: 10,
                                maxWidth: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  color: '#595959',
                                  marginLeft: 0,
                                  marginRight: 10,
                                  fontSize: 20,
                                }}
                              >
                                Detalhes da entrega:
                              </p>
                              {DATA.esttii == 0 ? null : (
                                <img
                                  src={DATA.dlvrdata.img}
                                  style={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: 40 / 2,
                                  }}
                                />
                              )}
                            </div>
                            {DATA.esttii == 0 ? null : (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  marginBottom: 10,
                                }}
                              >
                                <p
                                  style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                  }}
                                >
                                  Nome:{' '}
                                </p>
                                <p
                                  style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    marginLeft: 5,
                                  }}
                                >
                                  {DATA.dlvrdata.nome}
                                </p>
                              </div>
                            )}
                            {DATA.esttii == 0 ? null : (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  marginBottom: 10,
                                }}
                              >
                                <p
                                  style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                  }}
                                >
                                  Nota:{' '}
                                </p>
                                <p
                                  style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    marginLeft: 5,
                                  }}
                                >
                                  {DATA.dlvrdata.nota.toFixed(2)}
                                </p>
                              </div>
                            )}
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingLeft: 10,
                                paddingRight: 10,
                                marginBottom: 10,
                              }}
                            >
                              <p
                                style={{
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              >
                                Codigo de retirada:{' '}
                              </p>
                              <p
                                style={{
                                  marginTop: 0,
                                  marginBottom: 0,
                                  fontWeight: 'bold',
                                }}
                              >
                                {DATA.cdgstore}
                              </p>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingLeft: 10,
                                paddingRight: 10,
                                marginBottom: 10,
                              }}
                            >
                              <p
                                style={{
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              >
                                Forma de pagamento:{' '}
                              </p>
                              <p
                                style={{
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              >
                                {DATA.frm}
                              </p>
                            </div>
                            {DATA.entivld ? (
                              <Tooltip
                                title={
                                  'O entregador não esperou ou cancelou a entrega fora do local da entrega'
                                }
                                arrow
                              >
                                <Chip
                                  icon={
                                    <WarningIcon style={{color: '#FF6600'}} />
                                  }
                                  label={
                                    'O entregador não esperou ou cancelou a entrega fora do local da entrega'
                                  }
                                  style={{
                                    width: '100%',
                                    backgroundColor: '#f4ff44',
                                    color: '#3D3D3D',
                                    fontSize: 12,
                                    fontWeight: 'normal',
                                    marginBottom: 8,
                                    padding: 5,
                                    whiteSpace: 'normal', // Permite quebrar linhas
                                    wordWrap: 'break-word', // Garante que palavras grandes não sejam cortadas
                                    textAlign: 'left', // Ajusta a posição do texto
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={
                                  'O entregador esperou os 10 minutos e cancelou o pedido no local'
                                }
                                arrow
                              >
                                <Chip
                                  icon={
                                    <CheckCircleIcon
                                      style={{color: '#06448e'}}
                                    />
                                  }
                                  label={
                                    'O entregador esperou os 10 minutos e cancelou o pedido no local'
                                  }
                                  style={{
                                    width: '100%',
                                    backgroundColor: '#E8FFFF',
                                    color: '#3D3D3D',
                                    fontSize: 12,
                                    fontWeight: 'normal',
                                    marginBottom: 8,
                                    padding: 5,
                                    whiteSpace: 'normal', // Permite quebrar linhas
                                    wordWrap: 'break-word', // Garante que palavras grandes não sejam cortadas
                                    textAlign: 'left', // Ajusta a posição do texto
                                  }}
                                />
                              </Tooltip>
                            )}
                            <Divider />
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingLeft: 10,
                                paddingRight: 10,
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            >
                              <p
                                style={{
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              >
                                Valor:{' '}
                              </p>
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              >
                                {realFormat(DATA.frete)}
                              </p>
                            </div>
                            {/* DATA.esttii == -1 ? (
                              
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  marginTop: 10,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                }}
                              >
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  color={'primary'}
                                  icon={<LoopIcon />}
                                  label="Mudar para entrega própria"
                                  onClick={() => changeTypeDelivery()}
                                />
                              </div>
                            ) : null */}
                            <div style={{marginLeft: 10, marginRight: 10}}>
                              <p
                                style={{
                                  fontSize: 12,
                                  marginTop: 10,
                                  marginBottom: 0,
                                }}
                              >
                                {DATA.end}
                              </p>
                              <p
                                style={{
                                  fontSize: 12,
                                  marginBottom: 0,
                                  marginTop: 0,
                                }}
                              >
                                {DATA.subend}
                              </p>
                              <p
                                style={{
                                  fontSize: 12,
                                  marginBottom: 0,
                                  marginTop: 0,
                                }}
                              >
                                {DATA.ref}
                              </p>
                            </div>
                          </Box>
                        ) : null}

                        <Box
                          style={{
                            paddingTop: 1,
                            paddingBottom: 10,
                            marginTop: 10,
                            borderRadius: 10,
                          }}
                          boxShadow={3}
                        >
                          <div
                            style={{
                              backgroundColor: '#FFffff',
                              width: '100%',
                              height: 'auto',
                              paddingLeft: 10,
                              paddingRight: 10,
                            }}
                          ></div>
                          <div
                            style={{
                              paddingLeft: 0,
                              paddingRight: 0,
                              marginLeft: 10,
                              marginRight: 10,
                              maxWidth: '100%',
                            }}
                          >
                            <p
                              style={{
                                fontWeight: 'bold',
                                color: '#595959',
                                marginLeft: 0,
                                marginRight: 10,
                                fontSize: 20,
                              }}
                            >
                              Detalhes do pedido:
                            </p>

                            {DATA.bEntrega ? (
                              <Box style={{borderRadius: 3}} boxShadow={3}>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    marginLeft: 20,
                                    marginRight: 20,
                                  }}
                                >
                                  <div>
                                    <p
                                      style={{
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        color: '#393939',
                                        marginBottom: 0,
                                        marginTop: 10,
                                      }}
                                    >
                                      {DATA.end}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: 16,
                                        color: '#393939',
                                        marginBottom: 0,
                                        marginTop: 5,
                                      }}
                                    >
                                      {DATA.subend}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: 14,
                                        color: '#797979',
                                        marginBottom: 0,
                                        marginTop: 5,
                                      }}
                                    >
                                      {DATA.comp}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: 14,
                                        color: '#797979',
                                        marginBottom: 10,
                                        marginTop: 5,
                                      }}
                                    >
                                      {DATA.ref}
                                    </p>
                                    {DATA.hasObsEntrega ? (
                                      <div style={{display: 'flex'}}>
                                        <p
                                          style={{
                                            fontSize: 14,
                                            color: '#797979',
                                            marginBottom: 10,
                                            marginTop: 5,
                                          }}
                                        >
                                          Observação:{' '}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: 14,
                                            color: '#393939',
                                            marginBottom: 10,
                                            marginTop: 5,
                                            marginLeft: 5,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {DATA.obsEntrega}
                                        </p>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    {DATA.bEntrega ? (
                                      <p
                                        style={{
                                          color: '#FFFFFF',
                                          backgroundColor: '#06448e',
                                          paddingTop: 3,
                                          paddingBottom: 3,
                                          paddingRight: 10,
                                          paddingLeft: 10,
                                          borderRadius: 30,
                                          fontSize: 12,
                                        }}
                                      >
                                        Entrega
                                      </p>
                                    ) : (
                                      <p
                                        style={{
                                          color: '#FFFFFF',
                                          backgroundColor: '#06448e',
                                          paddingTop: 3,
                                          paddingBottom: 3,
                                          paddingRight: 10,
                                          paddingLeft: 10,
                                          borderRadius: 30,
                                          fontSize: 12,
                                        }}
                                      >
                                        Retirada
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </Box>
                            ) : (
                              <Box style={{borderRadius: 3}} boxShadow={3}>
                                <div
                                  style={{
                                    justifyContent: 'space-between',
                                    marginLeft: 5,
                                    marginRight: 5,
                                  }}
                                >
                                  <div>
                                    <p
                                      style={{fontSize: 18, fontWeight: 'bold'}}
                                    >
                                      Retirada na loja
                                    </p>
                                    <p style={{color: '#999999'}}>
                                      O cliente irá retirar o produto na loja.
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    {DATA.bEntrega ? (
                                      <p
                                        style={{
                                          color: '#FFFFFF',
                                          backgroundColor: '#06448e',
                                          paddingTop: 3,
                                          paddingBottom: 3,
                                          paddingRight: 10,
                                          paddingLeft: 10,
                                          borderRadius: 30,
                                          fontSize: 12,
                                        }}
                                      >
                                        Entrega
                                      </p>
                                    ) : (
                                      <p
                                        style={{
                                          color: '#FFFFFF',
                                          backgroundColor: '#06448e',
                                          paddingTop: 3,
                                          paddingBottom: 3,
                                          paddingRight: 10,
                                          paddingLeft: 10,
                                          borderRadius: 30,
                                          fontSize: 12,
                                        }}
                                      >
                                        Retirada
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </Box>
                            )}

                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 10,
                                  marginRight: 10,
                                }}
                              >
                                <p style={{marginBottom: 0}}>N° do pedido: </p>
                                <p
                                  style={{
                                    marginLeft: 3,
                                    fontWeight: 'bold',
                                    color: '#393939',
                                    marginBottom: 0,
                                  }}
                                >
                                  # {DATA.npd}
                                </p>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 10,
                                  marginRight: 10,
                                }}
                              >
                                <p style={{marginBottom: 0, marginTop: 0}}>
                                  Forma de pagamento:{' '}
                                </p>
                                <p
                                  style={{
                                    marginLeft: 3,
                                    fontWeight: 'bold',
                                    color: '#393939',
                                    marginBottom: 0,
                                    marginTop: 0,
                                  }}
                                >
                                  {DATA.frm}
                                </p>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 10,
                                  marginRight: 10,
                                  marginTop: 0,
                                  marginBottom: 10,
                                }}
                              >
                                <p style={{marginTop: 0, marginBottom: 0}}>
                                  Detalhes do pagamento:{' '}
                                </p>
                                <p
                                  style={{
                                    marginLeft: 3,
                                    fontWeight: 'bold',
                                    color: '#393939',
                                    marginTop: 0,
                                    marginBottom: 0,
                                  }}
                                >
                                  {DATA.frmdetails}
                                </p>
                              </div>
                              <Divider variant="middle" />
                            </div>
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 10,
                                  marginRight: 10,
                                  flexDirection: 'column',
                                }}
                              >
                                <p>Itens: </p>
                                {DATA.itens.length === 0 ? null : <Lista />}
                              </div>
                              <Divider variant="middle" />
                            </div>

                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 10,
                                  marginRight: 10,
                                  justifyContent: 'space-between',
                                }}
                              >
                                <p>Taxa de serviço: </p>
                                <p
                                  style={{
                                    marginLeft: 3,
                                    fontWeight: 'bold',
                                    color: '#393939',
                                  }}
                                >
                                  R$ {format(DATA.txcr)}
                                </p>
                              </div>
                              <Divider variant="middle" />
                            </div>

                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 10,
                                  marginRight: 10,
                                  justifyContent: 'space-between',
                                }}
                              >
                                <p>Desconto: </p>
                                <p
                                  style={{
                                    marginLeft: 3,
                                    fontWeight: 'bold',
                                    color: '#393939',
                                  }}
                                >
                                  R$ {format(DATA.dsc)}
                                </p>
                              </div>
                              <Divider variant="middle" />
                            </div>

                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 10,
                                  marginRight: 10,
                                  justifyContent: 'space-between',
                                }}
                              >
                                <p>Taxa de entrega: </p>
                                <p
                                  style={{
                                    marginLeft: 3,
                                    fontWeight: 'bold',
                                    color: '#393939',
                                  }}
                                >
                                  R$ {format(DATA.frete)}
                                </p>
                              </div>
                              <Divider variant="middle" />
                            </div>

                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 10,
                                  marginRight: 10,
                                  justifyContent: 'space-between',
                                }}
                              >
                                <p>Subtotal: </p>
                                <p
                                  style={{
                                    marginLeft: 3,
                                    fontWeight: 'bold',
                                    color: '#393939',
                                  }}
                                >
                                  R$ {format(DATA.total)}
                                </p>
                              </div>
                              <Divider variant="middle" />
                            </div>
                          </div>
                        </Box>
                        <div style={{display: 'none'}}>
                          <ComponentToPrint
                            ref={componentRef}
                            nome={DATA.nome}
                            npd={'#' + DATA.npd}
                            estabelecimento={estabelecimento}
                            data={dateformatinvert(DATA.hracpt)}
                            prev={dateformatinvert(DATA.hrprev)}
                            cont={DATA.ctt}
                            end={DATA.end}
                            subend={DATA.subend}
                            comp={DATA.comp}
                            refer={DATA.ref}
                            itens={DATA.itens}
                            total={DATA.total}
                            frete={DATA.frete}
                            dsc={DATA.dsc}
                            frm={DATA.frm}
                            obs={DATA.obsEntrega}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <DialogActions style={{position: 'relative', bottom: 0}}>
                    <div
                      style={{
                        alignItems: 'center',
                      }}
                    ></div>
                    {DATA.hrrfnd > 0 ? (
                      <Button
                        color={'primary'}
                        size={'small'}
                        onClick={() => setopenarquivaitem(true)}
                      >
                        Arquivar
                      </Button>
                    ) : isBefore24h ? (
                      <Button
                        onClick={() => setOpenDialogBox(true)}
                        size={'small'}
                        color={'primary'}
                      >
                        Responder
                      </Button>
                    ) : (
                      <p>
                        Reembolso só é possível 24 horas após a aceitação do
                        pedido
                      </p>
                    )}
                  </DialogActions>
                </Dialog>
                <Dialog
                  onClose={() => setbopenentrega(false)}
                  style={{
                    marginLeft: isVerySmall ? -20 : 0,
                    marginRight: isVerySmall ? -20 : 0,
                    paddingLeft: 0,
                    minWidth: isVerySmall ? '80%' : 0,
                  }}
                  aria-labelledby="customized-dialog-title"
                  open={bopenentrega}
                >
                  <div
                    style={{
                      width: '100%',
                      backgroundColor: '#ffffff',
                    }}
                  >
                    <div>
                      <div style={{marginLeft: 10, marginRight: 10}}>
                        <p
                          style={{
                            color: '#000000',
                            fontSize: 12,
                            background: '#FFFFCC',
                            padding: 5,
                          }}
                        >
                          Pedidos com entrega Irê it sofrem reajuste. Onde a
                          taxa da Irê it {iitx * 100}% vai para {iietx * 100}%
                          do valor do pedido. Caso tenha dúvida, entre em
                          contato com o suporte.
                        </p>
                      </div>

                      <Box
                        style={{
                          paddingTop: 1,
                          paddingBottom: 10,
                          marginTop: 10,
                          borderRadius: 10,
                          marginLeft: 10,
                          marginRight: 10,
                          alignContent: 'center',
                        }}
                        boxShadow={3}
                      >
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 16,
                            marginLeft: 10,
                            marginTop: 10,
                            marginBottom: 5,
                          }}
                        >
                          Informações da entrega
                        </p>
                        <FormControl
                          style={{
                            marginTop: 0,
                            width: '90%',
                            alignItems: 'center',
                          }}
                          className={classes.formControl}
                        >
                          <InputLabel
                            style={{fontSize: 14}}
                            id="demo-simple-select-label"
                          >
                            Pedido
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={p1}
                            onChange={handleChange}
                            style={{
                              marginTop: 14,
                              width: '100%',
                            }}
                          >
                            {(Array.isArray(entregapedidos)
                              ? entregapedidos.map(item => [item])
                              : Object.entries(entregapedidos)
                            ).map(item => {
                              return (
                                <MenuItem
                                  style={{fontSize: 14}}
                                  key={item[0]._id}
                                  value={item[0]}
                                >
                                  # {item[0].npd}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <TextField
                          style={{
                            width: '90%',
                            marginTop: 10,
                            marginLeft: 10,
                          }}
                          inputProps={{
                            readOnly: true,
                            style: {fontSize: 14},
                            maxLength: 150,
                            color: '#393939',
                          }}
                          InputLabelProps={{
                            style: {fontSize: 14, color: '#393939'},
                          }}
                          id="nome"
                          label="Nome do cliente"
                          value={np1}
                        />
                        <TextField
                          style={{
                            width: '90%',
                            marginTop: 10,
                            marginLeft: 10,
                          }}
                          inputProps={{
                            readOnly: true,
                            style: {fontSize: 14},
                            maxLength: 150,
                            color: '#393939',
                          }}
                          InputLabelProps={{
                            style: {fontSize: 14, color: '#393939'},
                          }}
                          id="contato"
                          label="Contato"
                          value={cp1}
                        />
                        {p1 === undefined ? (
                          <div style={{marginLeft: 10, marginRight: 10}}>
                            <p
                              style={{
                                fontSize: 12,
                                marginTop: 10,
                                marginBottom: 0,
                                color: '#B3B3B3',
                              }}
                            >
                              Endereço
                            </p>
                            <p
                              style={{
                                fontSize: 12,
                                marginBottom: 0,
                                marginTop: 0,
                                color: '#B3B3B3',
                              }}
                            >
                              Bairro - Cidade
                            </p>
                            <p
                              style={{
                                fontSize: 12,
                                marginBottom: 0,
                                marginTop: 0,
                                color: '#B3B3B3',
                              }}
                            >
                              Referência
                            </p>
                          </div>
                        ) : (
                          <div style={{marginLeft: 10, marginRight: 10}}>
                            <p
                              style={{
                                fontSize: 12,
                                marginTop: 10,
                                marginBottom: 0,
                              }}
                            >
                              {p1.end}
                            </p>
                            <p
                              style={{
                                fontSize: 12,
                                marginBottom: 0,
                                marginTop: 0,
                              }}
                            >
                              {p1.subend}
                            </p>
                            <p
                              style={{
                                fontSize: 12,
                                marginBottom: 0,
                                marginTop: 0,
                              }}
                            >
                              {p1.referencia}
                            </p>
                          </div>
                        )}
                      </Box>
                      {isp2 ? (
                        <Box
                          style={{
                            paddingTop: 1,
                            paddingBottom: 10,
                            marginTop: 10,
                            borderRadius: 10,
                            marginLeft: 10,
                            marginRight: 10,
                            alignContent: 'center',
                          }}
                          boxShadow={3}
                        >
                          <div
                            style={{
                              display: 'flex',
                              marginTop: 10,
                              marginLeft: 10,
                              marginRight: 10,
                              marginBottom: 5,
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontWeight: 'bold',
                                fontSize: 16,
                              }}
                            >
                              2° Entrega
                            </p>
                            <Chip
                              variant="outlined"
                              size="small"
                              color={'secondary'}
                              icon={<DeleteIcon />}
                              label="Cancelar"
                              onClick={() => deletep2()}
                            />
                          </div>

                          <FormControl
                            style={{
                              marginTop: 0,
                              width: '90%',
                              alignItems: 'center',
                            }}
                            className={classes.formControl}
                          >
                            <InputLabel
                              style={{fontSize: 14}}
                              id="demo-simple-select-label"
                            >
                              Pedido
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={p2}
                              onChange={handleChange2}
                              style={{
                                marginTop: 14,
                                width: '100%',
                              }}
                            >
                              {(Array.isArray(entregapedidos)
                                ? entregapedidos.map(item => [item])
                                : Object.entries(entregapedidos)
                              ).map(item => {
                                return (
                                  <MenuItem
                                    style={{fontSize: 14}}
                                    key={item[0]._id}
                                    value={item[0]}
                                  >
                                    # {item[0].npd}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          <TextField
                            style={{
                              width: '90%',
                              marginTop: 10,
                              marginLeft: 10,
                            }}
                            inputProps={{
                              readOnly: true,
                              style: {fontSize: 14},
                              maxLength: 150,
                              color: '#393939',
                            }}
                            InputLabelProps={{
                              style: {fontSize: 14, color: '#393939'},
                            }}
                            id="nome"
                            label="Nome do cliente"
                            value={np2}
                          />
                          <TextField
                            style={{
                              width: '90%',
                              marginTop: 10,
                              marginLeft: 10,
                            }}
                            inputProps={{
                              readOnly: true,
                              style: {fontSize: 14},
                              maxLength: 150,
                              color: '#393939',
                            }}
                            InputLabelProps={{
                              style: {fontSize: 14, color: '#393939'},
                            }}
                            id="contato"
                            label="Contato"
                            value={cp2}
                          />
                          {p2 === undefined ? (
                            <div style={{marginLeft: 10, marginRight: 10}}>
                              <p
                                style={{
                                  fontSize: 12,
                                  marginTop: 10,
                                  marginBottom: 0,
                                  color: '#B3B3B3',
                                }}
                              >
                                Endereço
                              </p>
                              <p
                                style={{
                                  fontSize: 12,
                                  marginBottom: 0,
                                  marginTop: 0,
                                  color: '#B3B3B3',
                                }}
                              >
                                Bairro - Cidade
                              </p>
                              <p
                                style={{
                                  fontSize: 12,
                                  marginBottom: 0,
                                  marginTop: 0,
                                  color: '#B3B3B3',
                                }}
                              >
                                Referência
                              </p>
                            </div>
                          ) : (
                            <div style={{marginLeft: 10, marginRight: 10}}>
                              <p
                                style={{
                                  fontSize: 12,
                                  marginTop: 10,
                                  marginBottom: 0,
                                }}
                              >
                                {p2.end}
                              </p>
                              <p
                                style={{
                                  fontSize: 12,
                                  marginBottom: 0,
                                  marginTop: 0,
                                }}
                              >
                                {p2.subend}
                              </p>
                              <p
                                style={{
                                  fontSize: 12,
                                  marginBottom: 0,
                                  marginTop: 0,
                                }}
                              >
                                {p2.referencia}
                              </p>
                            </div>
                          )}
                        </Box>
                      ) : null}
                      {isp3 ? (
                        <Box
                          style={{
                            paddingTop: 1,
                            paddingBottom: 10,
                            marginTop: 10,
                            borderRadius: 10,
                            marginLeft: 10,
                            marginRight: 10,
                            alignContent: 'center',
                          }}
                          boxShadow={3}
                        >
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: 16,
                              marginLeft: 10,
                              marginTop: 10,
                              marginBottom: 5,
                            }}
                          >
                            3° Entrega
                          </p>
                          <FormControl
                            style={{
                              marginTop: 0,
                              width: '90%',
                              alignItems: 'center',
                            }}
                            className={classes.formControl}
                          >
                            <InputLabel
                              style={{fontSize: 14}}
                              id="demo-simple-select-label"
                            >
                              Pedido
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={p1}
                              onChange={handleChange3}
                              style={{
                                marginTop: 14,
                                width: '100%',
                              }}
                            >
                              {(Array.isArray(entregapedidos)
                                ? entregapedidos.map(item => [item])
                                : Object.entries(entregapedidos)
                              ).map(item => {
                                return (
                                  <MenuItem
                                    style={{fontSize: 14}}
                                    key={item[0]._id}
                                    value={item[0]}
                                  >
                                    # {item[0].npd}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          <TextField
                            style={{
                              width: '90%',
                              marginTop: 10,
                              marginLeft: 10,
                            }}
                            inputProps={{
                              readOnly: true,
                              style: {fontSize: 14},
                              maxLength: 150,
                              color: '#393939',
                            }}
                            InputLabelProps={{
                              style: {fontSize: 14, color: '#393939'},
                            }}
                            id="nome"
                            label="Nome do cliente"
                            value={np3}
                          />
                          <TextField
                            style={{
                              width: '90%',
                              marginTop: 10,
                              marginLeft: 10,
                            }}
                            inputProps={{
                              readOnly: true,
                              style: {fontSize: 14},
                              maxLength: 150,
                              color: '#393939',
                            }}
                            InputLabelProps={{
                              style: {fontSize: 14, color: '#393939'},
                            }}
                            id="contato"
                            label="Contato"
                            value={cp3}
                          />
                          {p1 === undefined ? (
                            <div style={{marginLeft: 10, marginRight: 10}}>
                              <p
                                style={{
                                  fontSize: 12,
                                  marginTop: 10,
                                  marginBottom: 0,
                                  color: '#B3B3B3',
                                }}
                              >
                                Endereço
                              </p>
                              <p
                                style={{
                                  fontSize: 12,
                                  marginBottom: 0,
                                  marginTop: 0,
                                  color: '#B3B3B3',
                                }}
                              >
                                Bairro - Cidade
                              </p>
                              <p
                                style={{
                                  fontSize: 12,
                                  marginBottom: 0,
                                  marginTop: 0,
                                  color: '#B3B3B3',
                                }}
                              >
                                Referência
                              </p>
                            </div>
                          ) : (
                            <div style={{marginLeft: 10, marginRight: 10}}>
                              <p
                                style={{
                                  fontSize: 12,
                                  marginTop: 10,
                                  marginBottom: 0,
                                }}
                              >
                                {p3.end}
                              </p>
                              <p
                                style={{
                                  fontSize: 12,
                                  marginBottom: 0,
                                  marginTop: 0,
                                }}
                              >
                                {p3.subend}
                              </p>
                              <p
                                style={{
                                  fontSize: 12,
                                  marginBottom: 0,
                                  marginTop: 0,
                                }}
                              >
                                {p3.referencia}
                              </p>
                            </div>
                          )}
                        </Box>
                      ) : null}
                      <div style={{marginLeft: 10, marginRight: 10}}>
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              onChange={handleEntBack}
                              checked={isEntBack}
                              name="backent"
                            />
                          }
                          style={{color: '#393939'}}
                          label={
                            <p
                              style={{
                                fontSize: 14,
                                color: '#393939',
                              }}
                            >
                              Entregador precisa voltar a loja
                            </p>
                          }
                        />
                      </div>
                      <Divider />
                      {loadvlr ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginLeft: 10,
                            marginRight: 10,
                          }}
                        >
                          <p>Valor da entrega: </p>
                          <div
                            style={{
                              width: '2%',
                              height: '2%',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginRight: 20,
                            }}
                          >
                            <CircularProgress size={15} />
                          </div>
                        </div>
                      ) : (
                        <div>
                          {entnotfound ? (
                            <p
                              style={{
                                fontWeight: 'bold',
                                background: '#FFDDDD',
                                fontSize: 12,
                                color: '#FF0000',
                                padding: 5,
                                marginLeft: 10,
                                marginRight: 10,
                              }}
                            >
                              Nenhum entregador está disponível. Tente novamente
                              em algus minutos
                            </p>
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginLeft: 10,
                                marginRight: 10,
                              }}
                            >
                              <p>Valor da entrega: </p>
                              <p style={{fontWeight: 'bold'}}>
                                {realFormat(vlrent)}
                              </p>
                            </div>
                          )}
                        </div>
                      )}

                      <Divider />
                      <div
                        style={{
                          marginTop: 10,
                          width: '90%',
                          alignItems: 'center',
                          marginLeft: 20,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <InputLabel
                          style={{fontSize: 14}}
                          id="demo-simple-select-label"
                        >
                          Forma de pagamento
                        </InputLabel>
                        <p
                          style={{
                            marginTop: 0,
                            marginBottom: 0,
                            fontWeight: 'bold',
                          }}
                        >
                          {EDATA.frm}
                        </p>
                      </div>
                      <p
                        style={{
                          marginTop: 0,
                          marginBottom: 0,
                          marginLeft: 20,
                        }}
                      >
                        {EDATA.frmdetails}
                      </p>

                      {!isp2 ? null : (
                        <Chip
                          style={{
                            marginTop: 5,
                            marginBottom: 10,
                            marginLeft: 10,
                          }}
                          variant="outlined"
                          size="small"
                          color={'primary'}
                          icon={<AddIcon />}
                          label="Adicionar pedido"
                          onClick={() => open2pedido()}
                        />
                      )}
                    </div>
                  </div>

                  <DialogActions style={{position: 'relative', bottom: 0}}>
                    <Button
                      color={'secondary'}
                      size={'small'}
                      onClick={() => closeEntrega()}
                    >
                      Cancelar
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            ) : (
              <div style={{display: 'flex', marginTop: isSmall ? 10 : 0}}>
                <div
                  style={{
                    width: isSmall ? '40%' : '23%',
                    backgroundColor: '#E9E9E9',
                  }}
                >
                  <ListaPedidos />
                </div>
                <div
                  style={{
                    width: isSmall ? '60%' : '80%',
                    backgroundColor: '#ffffff',
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  {changeData ? (
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: height,
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : DATA === {} ||
                    DATA === undefined ||
                    DATA === null ||
                    pedidos.length === 0 ? (
                    <div
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: height,
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      <InfoIcon style={{color: '#999999', fontSize: 25}} />
                      {pedidos.length === 0 ? (
                        <p
                          style={{
                            fontSize: 25,
                            color: '#999999',
                            textAlign: 'center',
                          }}
                        >
                          Não há reembolsos em andamento.
                        </p>
                      ) : (
                        <p
                          style={{
                            fontSize: 25,
                            color: '#999999',
                            textAlign: 'center',
                          }}
                        >
                          Selecione um item
                        </p>
                      )}
                    </div>
                  ) : currentItem === '' ? (
                    <div
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: height,
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      <InfoIcon style={{color: '#999999', fontSize: 25}} />
                      <p
                        style={{
                          fontSize: 25,
                          color: '#999999',
                          textAlign: 'center',
                        }}
                      >
                        Selecione um item
                      </p>
                    </div>
                  ) : (
                    <div>
                      <Box
                        boxShadow={3}
                        style={{
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: 5,
                          paddingBottom: 5,
                          borderRadius: 10,
                          marginTop: 20,
                        }}
                      >
                        <p
                          style={{
                            color: '#000000',
                            fontWeight: 'bold',
                            fontSize: 20,
                            marginBottom: 0,
                          }}
                        >
                          Detalhes do reembolso
                        </p>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: 0,
                            marginBottom: 0,
                          }}
                        >
                          <p
                            style={{
                              color: '#999999',
                              marginRight: 10,
                              marginBottom: 0,
                            }}
                          >
                            Motivo:
                          </p>
                          <p
                            style={{
                              color: '#000000',
                              fontWeight: 'bold',
                              marginBottom: 0,
                            }}
                          >
                            {DATA.rqstrfnd}
                          </p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: 0,
                            marginBottom: 0,
                          }}
                        >
                          <p
                            style={{
                              color: '#999999',
                              marginRight: 10,
                              marginBottom: 0,
                            }}
                          >
                            Valor solicitado:
                          </p>
                          <p
                            style={{
                              color: '#000000',
                              fontWeight: 'bold',
                              marginBottom: 0,
                            }}
                          >
                            {realFormat(DATA.rqstvlrrfnd)}
                          </p>
                        </div>
                        <Divider variant="middle" style={{marginTop: 20}} />
                        <p
                          style={{
                            color: '#06448e',
                            fontWeight: 'bold',
                            fontSize: 16,
                            marginBottom: 0,
                          }}
                        >
                          Detalhes do cliente
                        </p>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: 0,
                            marginBottom: 0,
                          }}
                        >
                          <p
                            style={{
                              color: '#999999',
                              marginRight: 10,
                              marginBottom: 0,
                            }}
                          >
                            Nome:
                          </p>
                          <p
                            style={{
                              color: '#393939',
                              fontWeight: 'bold',
                              marginBottom: 0,
                            }}
                          >
                            {DATA.nome}
                          </p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: 0,
                            marginBottom: 0,
                          }}
                        >
                          <p style={{color: '#999999', marginRight: 10}}>
                            Contato:
                          </p>
                          <p style={{color: '#393939', fontWeight: 'bold'}}>
                            {DATA.ctt}
                          </p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: 0,
                            marginBottom: 0,
                          }}
                        >
                          <p
                            style={{
                              color: '#999999',
                              marginRight: 10,
                              marginTop: 0,
                              marginBottom: 0,
                            }}
                          >
                            Número de reembolsos:
                          </p>
                          <p
                            style={{
                              color: '#393939',
                              fontWeight: 'bold',
                              marginTop: 0,
                              marginBottom: 0,
                            }}
                          >
                            {DATA.nrfd}
                          </p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: 0,
                            marginBottom: 0,
                          }}
                        >
                          <p
                            style={{
                              color: '#999999',
                              marginRight: 10,
                              marginTop: 0,
                              marginBottom: 0,
                            }}
                          >
                            Valor total de reembolso:
                          </p>
                          <p
                            style={{
                              color: '#393939',
                              fontWeight: 'bold',
                              marginTop: 0,
                              marginBottom: 0,
                            }}
                          >
                            {realFormat(DATA.vlrrfd)}
                          </p>
                        </div>
                      </Box>

                      {DATA.etrii ? (
                        <Box
                          style={{
                            paddingTop: 1,
                            paddingBottom: 10,
                            marginTop: 10,
                            borderRadius: 10,
                          }}
                          boxShadow={3}
                        >
                          <div
                            style={{
                              paddingLeft: 0,
                              paddingRight: 0,
                              marginLeft: 10,
                              marginRight: 10,
                              maxWidth: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <p
                              style={{
                                fontWeight: 'bold',
                                color: '#595959',
                                marginLeft: 0,
                                marginRight: 10,
                                fontSize: 20,
                              }}
                            >
                              Detalhes da entrega:
                            </p>
                            {DATA.esttii == 0 ? null : (
                              <img
                                src={DATA.dlvrdata.img}
                                style={{
                                  width: 40,
                                  height: 40,
                                  borderRadius: 40 / 2,
                                }}
                              />
                            )}
                          </div>
                          {DATA.esttii == 0 ? null : (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingLeft: 10,
                                paddingRight: 10,
                                marginBottom: 10,
                              }}
                            >
                              <p
                                style={{
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              >
                                Nome:{' '}
                              </p>
                              <p
                                style={{
                                  marginTop: 0,
                                  marginBottom: 0,
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  marginLeft: 5,
                                }}
                              >
                                {DATA.dlvrdata.nome}
                              </p>
                            </div>
                          )}
                          {DATA.esttii == 0 ? null : (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingLeft: 10,
                                paddingRight: 10,
                                marginBottom: 10,
                              }}
                            >
                              <p
                                style={{
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              >
                                Nota:{' '}
                              </p>
                              <p
                                style={{
                                  marginTop: 0,
                                  marginBottom: 0,
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  marginLeft: 5,
                                }}
                              >
                                {DATA.dlvrdata.nota.toFixed(2)}
                              </p>
                            </div>
                          )}
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              paddingLeft: 10,
                              paddingRight: 10,
                              marginBottom: 10,
                            }}
                          >
                            <p
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              Codigo de retirada:{' '}
                            </p>
                            <p
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                fontWeight: 'bold',
                              }}
                            >
                              {DATA.cdgstore}
                            </p>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              paddingLeft: 10,
                              paddingRight: 10,
                              marginBottom: 10,
                            }}
                          >
                            <p
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              Forma de pagamento:{' '}
                            </p>
                            <p
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              {DATA.frm}
                            </p>
                          </div>
                          {DATA.entivld ? (
                            <Tooltip
                              title={
                                'O entregador não esperou ou cancelou a entrega fora do local da entrega'
                              }
                              arrow
                            >
                              <Chip
                                icon={
                                  <WarningIcon style={{color: '#FF6600'}} />
                                }
                                label={
                                  'O entregador não esperou ou cancelou a entrega fora do local da entrega'
                                }
                                style={{
                                  width: '100%',
                                  backgroundColor: '#f4ff44',
                                  color: '#3D3D3D',
                                  fontSize: 12,
                                  fontWeight: 'normal',
                                  marginBottom: 8,
                                  padding: 5,
                                  whiteSpace: 'normal', // Permite quebrar linhas
                                  wordWrap: 'break-word', // Garante que palavras grandes não sejam cortadas
                                  textAlign: 'left', // Ajusta a posição do texto
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={
                                'O entregador esperou os 10 minutos e cancelou o pedido no local'
                              }
                              arrow
                            >
                              <Chip
                                icon={
                                  <CheckCircleIcon style={{color: '#06448e'}} />
                                }
                                label={
                                  'O entregador esperou os 10 minutos e cancelou o pedido no local'
                                }
                                style={{
                                  width: '100%',
                                  backgroundColor: '#E8FFFF',
                                  color: '#3D3D3D',
                                  fontSize: 12,
                                  fontWeight: 'normal',
                                  marginBottom: 8,
                                  padding: 5,
                                  whiteSpace: 'normal', // Permite quebrar linhas
                                  wordWrap: 'break-word', // Garante que palavras grandes não sejam cortadas
                                  textAlign: 'left', // Ajusta a posição do texto
                                }}
                              />
                            </Tooltip>
                          )}
                          <Divider />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              paddingLeft: 10,
                              paddingRight: 10,
                              marginTop: 10,
                              marginBottom: 10,
                            }}
                          >
                            <p
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              Valor:{' '}
                            </p>
                            <p
                              style={{
                                fontWeight: 'bold',
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              {realFormat(DATA.frete)}
                            </p>
                          </div>
                          <div style={{marginLeft: 10, marginRight: 10}}>
                            <p
                              style={{
                                fontSize: 12,
                                marginTop: 10,
                                marginBottom: 0,
                              }}
                            >
                              {DATA.end}
                            </p>
                            <p
                              style={{
                                fontSize: 12,
                                marginBottom: 0,
                                marginTop: 0,
                              }}
                            >
                              {DATA.subend}
                            </p>
                            <p
                              style={{
                                fontSize: 12,
                                marginBottom: 0,
                                marginTop: 0,
                              }}
                            >
                              {DATA.ref}
                            </p>
                          </div>
                        </Box>
                      ) : null}

                      <Box
                        style={{
                          paddingTop: 10,
                          paddingBottom: 10,
                          marginTop: 20,
                          borderRadius: 10,
                        }}
                        boxShadow={3}
                      >
                        <div
                          style={{
                            backgroundColor: '#FFffff',
                            width: '100%',
                            height: 'auto',
                            paddingLeft: 10,
                            paddingRight: 10,
                          }}
                        ></div>
                        <div
                          style={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            marginLeft: 10,
                            marginRight: 10,
                            maxWidth: '100%',
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 'bold',
                              color: '#595959',
                              marginLeft: 0,
                              marginRight: 10,
                              fontSize: 20,
                            }}
                          >
                            Detalhes do pedido:
                          </p>

                          {DATA.bEntrega ? (
                            <Box style={{borderRadius: 3}} boxShadow={3}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginLeft: 20,
                                  marginRight: 20,
                                }}
                              >
                                <div>
                                  <p
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 'bold',
                                      color: '#393939',
                                      marginBottom: 0,
                                      marginTop: 10,
                                    }}
                                  >
                                    {DATA.end}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 16,
                                      color: '#393939',
                                      marginBottom: 0,
                                      marginTop: 5,
                                    }}
                                  >
                                    {DATA.subend}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 14,
                                      color: '#797979',
                                      marginBottom: 0,
                                      marginTop: 5,
                                    }}
                                  >
                                    {DATA.comp}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 14,
                                      color: '#797979',
                                      marginBottom: 10,
                                      marginTop: 5,
                                    }}
                                  >
                                    {DATA.ref}
                                  </p>
                                  {DATA.hasObsEntrega ? (
                                    <div style={{display: 'flex'}}>
                                      <p
                                        style={{
                                          fontSize: 14,
                                          color: '#797979',
                                          marginBottom: 10,
                                          marginTop: 5,
                                        }}
                                      >
                                        Observação:{' '}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: 14,
                                          color: '#393939',
                                          marginBottom: 10,
                                          marginTop: 5,
                                          marginLeft: 5,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {DATA.obsEntrega}
                                      </p>
                                    </div>
                                  ) : null}
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  {DATA.bEntrega ? (
                                    <p
                                      style={{
                                        color: '#FFFFFF',
                                        backgroundColor: '#06448e',
                                        paddingTop: 3,
                                        paddingBottom: 3,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        borderRadius: 30,
                                        fontSize: 12,
                                      }}
                                    >
                                      Entrega
                                    </p>
                                  ) : (
                                    <p
                                      style={{
                                        color: '#FFFFFF',
                                        backgroundColor: '#06448e',
                                        paddingTop: 3,
                                        paddingBottom: 3,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        borderRadius: 30,
                                        fontSize: 12,
                                      }}
                                    >
                                      Retirada
                                    </p>
                                  )}
                                </div>
                              </div>
                            </Box>
                          ) : (
                            <Box style={{borderRadius: 3}} boxShadow={3}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginLeft: 20,
                                  marginRight: 20,
                                }}
                              >
                                <div>
                                  <p style={{fontSize: 18, fontWeight: 'bold'}}>
                                    Retirada na loja
                                  </p>
                                  <p style={{color: '#999999'}}>
                                    O cliente irá retirar o produto na loja.
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  {DATA.bEntrega ? (
                                    <p
                                      style={{
                                        color: '#FFFFFF',
                                        backgroundColor: '#06448e',
                                        paddingTop: 3,
                                        paddingBottom: 3,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        borderRadius: 30,
                                        fontSize: 12,
                                      }}
                                    >
                                      Entrega
                                    </p>
                                  ) : (
                                    <p
                                      style={{
                                        color: '#FFFFFF',
                                        backgroundColor: '#06448e',
                                        paddingTop: 3,
                                        paddingBottom: 3,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        borderRadius: 30,
                                        fontSize: 12,
                                      }}
                                    >
                                      Retirada
                                    </p>
                                  )}
                                </div>
                              </div>
                            </Box>
                          )}

                          <div>
                            <div
                              style={{
                                display: 'flex',
                                marginLeft: 10,
                                marginRight: 10,
                              }}
                            >
                              <p style={{marginBottom: 0}}>N° do pedido: </p>
                              <p
                                style={{
                                  marginLeft: 3,
                                  fontWeight: 'bold',
                                  color: '#393939',
                                  marginBottom: 0,
                                }}
                              >
                                # {DATA.npd}
                              </p>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                marginLeft: 10,
                                marginRight: 10,
                              }}
                            >
                              <p style={{marginBottom: 0, marginTop: 0}}>
                                Forma de pagamento:{' '}
                              </p>
                              <p
                                style={{
                                  marginLeft: 3,
                                  fontWeight: 'bold',
                                  color: '#393939',
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              >
                                {DATA.frm}
                              </p>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                marginLeft: 10,
                                marginRight: 10,
                                marginTop: 0,
                              }}
                            >
                              <p style={{marginTop: 0, marginBottom: 10}}>
                                Detalhes do pagamento:{' '}
                              </p>
                              <p
                                style={{
                                  marginLeft: 3,
                                  fontWeight: 'bold',
                                  color: '#393939',
                                  marginTop: 0,
                                  marginBottom: 10,
                                }}
                              >
                                {DATA.frmdetails}
                              </p>
                            </div>
                            <Divider variant="middle" />
                          </div>
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                marginLeft: 10,
                                marginRight: 10,
                                flexDirection: 'column',
                              }}
                            >
                              <p>Itens: </p>
                              {DATA.itens.length === 0 ? null : <Lista />}
                            </div>
                            <Divider variant="middle" />
                          </div>

                          <div>
                            <div
                              style={{
                                display: 'flex',
                                marginLeft: 10,
                                marginRight: 10,
                                justifyContent: 'space-between',
                              }}
                            >
                              <p>Taxa de serviço: </p>
                              <p
                                style={{
                                  marginLeft: 3,
                                  fontWeight: 'bold',
                                  color: '#393939',
                                }}
                              >
                                R$ {format(DATA.txcr)}
                              </p>
                            </div>
                            <Divider variant="middle" />
                          </div>

                          <div>
                            <div
                              style={{
                                display: 'flex',
                                marginLeft: 10,
                                marginRight: 10,
                                justifyContent: 'space-between',
                              }}
                            >
                              <p>Desconto: </p>
                              <p
                                style={{
                                  marginLeft: 3,
                                  fontWeight: 'bold',
                                  color: '#393939',
                                }}
                              >
                                R$ {format(DATA.dsc)}
                              </p>
                            </div>
                            <Divider variant="middle" />
                          </div>

                          <div>
                            <div
                              style={{
                                display: 'flex',
                                marginLeft: 10,
                                marginRight: 10,
                                justifyContent: 'space-between',
                              }}
                            >
                              <p>Taxa de entrega: </p>
                              <p
                                style={{
                                  marginLeft: 3,
                                  fontWeight: 'bold',
                                  color: '#393939',
                                }}
                              >
                                R$ {format(DATA.frete)}
                              </p>
                            </div>
                            <Divider variant="middle" />
                          </div>

                          <div>
                            <div
                              style={{
                                display: 'flex',
                                marginLeft: 10,
                                marginRight: 10,
                                justifyContent: 'space-between',
                              }}
                            >
                              <p>Subtotal: </p>
                              <p
                                style={{
                                  marginLeft: 3,
                                  fontWeight: 'bold',
                                  color: '#393939',
                                }}
                              >
                                R$ {format(DATA.total)}
                              </p>
                            </div>
                            <Divider variant="middle" />
                          </div>
                        </div>
                      </Box>
                      {DATA.hrrfnd == 0 ? (
                        <div
                          style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            marginTop: 20,
                            marginBottom: 100,
                          }}
                        >
                          <div></div>
                          <div style={{display: 'flex'}}>
                            {isBefore24h ? (
                              <Button
                                onClick={() => setOpenDialogBox(true)}
                                size={'medium'}
                                variant={'contained'}
                                color="primary"
                              >
                                Responder
                              </Button>
                            ) : (
                              <p>
                                Reembolso só é possível 24 horas após a
                                aceitação do pedido
                              </p>
                            )}
                          </div>
                        </div>
                      ) : DATA.hrrfnd > 0 ? (
                        <div
                          style={{
                            display: 'flex',
                            marginTop: 20,
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Button
                            variant={'contained'}
                            color={'primary'}
                            size={'small'}
                            onClick={() => setopenarquivaitem(true)}
                          >
                            Arquivar
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <AlertSending
        bopen={isSending}
        handleClose={() => setIsSending(false)}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
      <AlertAnswerBox
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 5,
        }}
        isOpen={openDialogBox}
        handleClose={() => setOpenDialogBox(false)}
        onSubmit={onSubmit}
        onRefuse={onRefuse}
        title={'Aprovação do reembolso'}
        description={DATA.rqstrfnd}
        isLoading={isLoadingAnwser}
        setLoading={setLoadingAnwser}
        setVlr={setVlr}
        vlr={vlr}
        handleNotiStack={handleNotiStack}
        vlrMax={DATA.total - DATA.dsc}
        vlrRqst={DATA.rqstvlrrfnd}
      />
    </div>
  );
};

export default Refounds;
